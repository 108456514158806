const activeItems = document.querySelector('.switcher')

if  (activeItems) {
  activeItems.addEventListener('click', ({target}) => {
    target.parentNode.classList.toggle('show')
  })

  document.body.addEventListener('click', function (event) {
    if (!activeItems.contains(event.target)) {
      activeItems.classList.remove('show')
    }
  })
}
