import Swiper, {Navigation, Pagination, Autoplay, EffectFade} from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

const sliderImage = document.querySelector('.swiper-image')

if (sliderImage) {
  Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

  new Swiper(sliderImage, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    speed: 500,
    autoplay: true,
    loop: false,
    slidersPerView: 1,
    pagination: {
      el: '.swiper-navigation__pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-navigation__next',
      prevEl: '.swiper-navigation__prev',
    },
  })
}
