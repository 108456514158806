const partnersMenu = document.querySelectorAll('.partner-menu')

if (partnersMenu) {
  partnersMenu.forEach(partnerMenu => {
    const links = partnerMenu.querySelectorAll('.partner-menu__link')

    links.forEach(link => {
      link.addEventListener('click', (event) => {
        let parent = link.parentElement.classList

        links.forEach(link => link.classList.remove('active'))

        if (link.classList.contains('expanded')) {
          event.preventDefault()
          const body = link.parentElement.querySelector('.partner-menu__sub-link')

          if (parent.contains('open-menu')) {
            body.removeAttribute('style')
            link.parentElement.classList.remove('open-menu')
          } else {
            body.style.height = body.scrollHeight + 'px'
            link.parentElement.classList.add('open-menu')
          }
        }

        link.classList.add('active')
      })
    })
  })
}
