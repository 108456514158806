import bodymovin from 'bodymovin'
import animationData from '../constants/animationNotFound.json'

const svgContainer = document.getElementById('svgContainer')

if (svgContainer) {
  bodymovin.loadAnimation({
    wrapper: svgContainer,
    animType: 'svg',
    loop: true,
    animationData: animationData
  })
}
