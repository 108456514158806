const blockShop = document.querySelector('.menu-page-btn-search')

if (blockShop) {
  blockShop.addEventListener('click', ({target}) => {
    let inputValue = blockShop.querySelector('select').value

    if (target.classList.contains('btn-search')) {
      Livewire.emit('changeShop', inputValue)
    }
  })
}
