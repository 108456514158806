const profileMap = document.querySelector('.profile-map')
const profileMapPage = document.querySelector('.profile-map-page')

if (profileMap && profileMapPage) {
  const mapKey = profileMap.dataset.key
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&region=ua&language=uk&libraries=places&callback=initMap`
  script.async = true
  const getLocations = profileMapPage.dataset.locations
  const getShops = profileMapPage.dataset.shops
  const bookingBlock = profileMapPage.querySelector('.booking-wrapper')
  const bookingInfoMessage = profileMapPage.querySelector('.booking-info-message')
  const nearestLocationsList = profileMapPage.querySelector('.nearest-locations__list')
  const btn = bookingBlock.querySelector('.booking-wrapper__btn')
  const canNotBooking = profileMapPage.querySelector('.booking-can-not')
  const googleSearch = profileMapPage.querySelector('.google-search')
  let bookingLocation = {
    lat: null,
    lng: null
  }
  const options = {
    componentRestrictions: {country: 'ua'},
    fields: ['address_components', 'geometry', 'icon', 'name'],
    strictBounds: false,
  }
  const country = 'Ukraine'
  const notDeliveryImg = '/images/maps/profile-cart-point.svg'
  const deliveryImg = '/images/maps/profile-delivery-point.svg'
  const bookingImg = '/images/maps/booking-point.svg'
  const notBookingImg = '/images/maps/booking-not-point.svg'
  const minZoomLevel = 14
  const maxZoomLevel = 11
  let geocoder, bounds, map, markerPosition, bookingMyCircle, autocomplete, searchArea, newMarkers, infoWindow,
    nearestList, checkValidate = {}
  let markers = []
  let myLocations = []

  const e = Math
  const D2R = e.PI / 180.0

  Number.prototype.toRadians = function () {
    return this * D2R
  }

  window.initMap = () => {
    bounds = new google.maps.LatLngBounds()
    geocoder = new google.maps.Geocoder()
    infoWindow = new google.maps.InfoWindow({content: ''})

    map = new google.maps.Map(profileMap, {
      zoom: maxZoomLevel,
      mapTypeId: 'roadmap',
      disableDefaultUI: true
    })

    autocomplete = new google.maps.places.Autocomplete(googleSearch, options)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      placeMarker(markers, place.geometry.location, true)
    })

    fetch(getShops)
      .then(res => res.json())
      .then(data => {
        data.forEach((item) => {
          addMarker(item)
        })
      })
      .then(() => {
        const listener = google.maps.event.addListener(map, 'idle', () => {
          if (map.getZoom() > 11) {
            setTimeout(() => {
              map.setZoom(14)
            }, 500)
          }
          google.maps.event.removeListener(listener)
        })
      })

    fetch(getLocations)
      .then(res => res.json())
      .then(data => {
        data.forEach(item => addMarkerMyLocation(item))
      })

    if (!canNotBooking) {
      map.addListener('click', (event) => {
        placeMarker(markers, event.latLng)
      })
    }
  }

  function addMarker({position, isDelivery, content}) {
    const bookingCircle = {
      strokeColor: '#999999',
      strokeWeight: 1,
      fillColor: '#999999',
      fillOpacity: 0.35,
      map: map,
      radius: position.radius / 2,
    }

    const marker = new google.maps.Marker({
      position: position,
      map,
      icon: isDelivery ? notDeliveryImg : deliveryImg,
    })

    const circle = new google.maps.Circle(bookingCircle)
    circle.bindTo('center', marker, 'position')

    marker.addListener('click', () => markerClick(marker, content, infoWindow))
    markers.push({id: position.id, marker, circle, info: content.info})

    bounds.extend(position)
    map.fitBounds(bounds)
  }

  function addMarkerMyLocation({position}) {
    const marker = new google.maps.Marker({
      position: position,
      map,
      animation: google.maps.Animation.DROP,
      icon: bookingImg,
    })

    marker.addListener('click', () => markerClick(marker))
    myLocations.push({id: position.id, marker})
  }

  function placeMarker(places, location, isZoom) {
    if (isZoom) {
      map.panTo(location)
      map.setZoom(minZoomLevel)
    }

    getAddress(location).then((result) => {
      if (!!markerPosition) {
        markerPosition.setPosition(location)
        bookingMyCircle.setRadius(result)
        // searchArea.setRadius((result * 2))
        validateRadius(places, bookingMyCircle)
        showBookingBlock(bookingMyCircle)
      } else {
        markerPosition = new google.maps.Marker({
          position: location,
          map: map,
          icon: bookingImg,
          draggable: true
        })

        bookingMyCircle = new google.maps.Circle({
          strokeColor: '#45bd60',
          strokeWeight: 1,
          fillColor: '#45bd60',
          fillOpacity: 0.35,
          center: location,
          map: map,
          radius: result,
        })

        searchArea = new google.maps.Circle({
          strokeColor: 'transparent',
          strokeWeight: 1,
          fillColor: 'transparent',
          fillOpacity: 0.35,
          center: location,
          map: map,
          radius: 2000,
        })

        bookingMyCircle.bindTo('center', markerPosition, 'position')
        searchArea.bindTo('center', markerPosition, 'position')
        markerPosition.circle = bookingMyCircle
        markerPosition.circle = searchArea
        validateRadius(places, bookingMyCircle)
        showBookingBlock(bookingMyCircle)

        markerPosition.addListener('drag', () => {
          validateRadius(places, bookingMyCircle)
        })

        markerPosition.addListener('dragend', (event) => {
          showBookingBlock(bookingMyCircle)

          getAddress(event.latLng).then((result) => {
            bookingMyCircle.setRadius(result)
            // searchArea.setRadius((result * 2))
          })
        })
      }
    })
  }

  function getAddress(latLng) {
    let locationName = bookingBlock.querySelector('.booking-wrapper__text')

    return new Promise((resolve) => {
      geocoder.geocode({latLng: latLng},
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {

              if (results[0].types[0] === 'premise' || results[0].types[0] === 'street_address') {
                locationName.innerHTML = `${results[0].address_components[1].long_name} ${results[0].address_components[0].long_name}, ${results[0].address_components[2].long_name}`
                googleSearch.value = `${results[0].address_components[1].long_name} ${results[0].address_components[0].long_name}, ${results[0].address_components[2].long_name}`
              } else {
                locationName.innerHTML = `${results[0].address_components[1].long_name}, ${results[0].address_components[2].long_name}`
                googleSearch.value = `${results[0].address_components[1].long_name}, ${results[0].address_components[2].long_name}`
              }

              let city = '', region = ''
              const address_components = results[0].address_components

              for (let i = 0; i < address_components.length; i++) {
                if (address_components[i].types[0] === 'locality' && address_components[i].types[1] === 'political') {
                  city = address_components[i].long_name
                }

                if (address_components[i].types[0] === 'political' && address_components[i].types[1] === 'sublocality') {
                  region = address_components[i].long_name
                }
              }

              postData('/api/location/radius', {city, region})
                .then(({radius}) => resolve(radius / 2))

            } else {
              console.log('No results')
            }
          } else {
            console.log('status', status)
          }
        })
    })
  }

  function validateRadius(places, bookingCircle) {
    newMarkers = markers.filter((marker) =>
      google.maps.geometry.spherical.computeDistanceBetween(
        marker.marker.getPosition(), searchArea.getCenter()) <= searchArea.getRadius()
    )

    if (newMarkers.length) {
      newMarkers.forEach(place => {
        checkValidate[place.id] = hasIntersections(place.circle, bookingCircle)

        if (Object.values(checkValidate).indexOf(true) > -1) {
          markerPosition.setIcon(notBookingImg)
          bookingCircle.setOptions({
            strokeColor: '#EE5454',
            fillColor: '#EE5454',
          })
        } else {
          markerPosition.setIcon(bookingImg)
          bookingCircle.setOptions({
            strokeColor: '#45bd60',
            fillColor: '#45bd60',
          })
        }
      })
    } else {
      markerPosition.setIcon(bookingImg)
      bookingCircle.setOptions({
        strokeColor: '#45bd60',
        fillColor: '#45bd60',
      })
    }
  }

  function markerClick(marker, content, infoWindow) {
    map.panTo(marker.position)
    map.setZoom(minZoomLevel)
    limitZoom(map)
    toggleMarkerAnimation(marker, 400)

    if (infoWindow) {
      openInfoWindow(marker, infoWindow, content)
    }
  }

  function distance(lat0, lng0, lat1, lng1) {
    const rlat0 = lat0.toRadians()
    const rlng0 = lng0.toRadians()
    const rlat1 = lat1.toRadians()
    const rlng1 = lng1.toRadians()

    const Δlat = (rlat1 - rlat0)
    const Δlng = (rlng1 - rlng0)

    const a = e.pow(e.sin(Δlat / 2), 2) + e.pow(e.sin(Δlng / 2), 2) * e.cos(rlat0) * e.cos(rlat1)
    const c = 2 * e.asin(e.sqrt(a))
    const d = c * 6378137

    return d
  }

  function hasIntersections(circle0, circle1) {
    const center0 = circle0.getCenter()
    const center1 = circle1.getCenter()

    const maxDist = circle0.getRadius() + circle1.getRadius()
    const actualDist = distance(center0.lat(), center0.lng(), center1.lat(), center1.lng())

    return maxDist >= actualDist
  }

  function limitZoom(map) {
    const zoom = map.getZoom()

    map.setZoom(zoom > minZoomLevel ? minZoomLevel : zoom)
  }

  function showBookingBlock(position) {
    let markers = []

    for (let key in checkValidate) {
      if (checkValidate[key]) {
        newMarkers.forEach( item => item.id === +key ? markers.push(item) : false);
      }
    }

    createNearestList(markers)

    bookingLocation = {
      lat: position.getCenter().lat(),
      lng: position.getCenter().lng()
    }

    if (markers.length) {
      bookingBlock.classList.remove('show')
      nearestLocationsList.parentNode.classList.add('show')
      bookingInfoMessage.remove()
    } else {
      bookingBlock.classList.add('show')
      nearestLocationsList.parentNode.classList.remove('show')
      bookingInfoMessage.remove()
    }
  }

  if (btn) {
    btn.addEventListener('click', () => {
      const checkLocation = profileMapPage.dataset.routeBook
      const blockLocation = profileMapPage.querySelector('#my-locations-container')

      postData(checkLocation, {...bookingLocation})
        .then((data) => {
          let text = bookingBlock.querySelector('.booking-wrapper__text')

          if (data.status) {
            blockLocation.innerHTML = data.html
            addEventListLocation()
            googleSearch.value = null

            fetch(getLocations)
              .then(res => res.json())
              .then(data => {
                data.forEach(item => addMarkerMyLocation(item))
              })
          }

          text.innerText = `${data.msg}`
        })
    })
  }

  addEventListLocation()

  function addEventListLocation() {
    const bookingItems = profileMapPage.querySelectorAll('.booking-item')

    if (bookingItems) {
      bookingItems.forEach(item => {
        let markerItem = item.querySelector('.booking-item__main')
        let markerRemoved = item.querySelector('.form-header__link')

        if (markerRemoved) {
          markerRemoved.addEventListener('click', ({target}) => {
            postData(target.dataset.close, {})
              .then((data) => {
                myLocations.forEach(location => {
                  if (target.dataset.index === location.id) {
                    item.remove()
                    location.marker.setMap(null)
                  }
                })
              })
          })
        }

        markerItem.addEventListener('click', ({target}) => {
          myLocations.forEach(location => {
            if (target.dataset.index === location.id) {
              markerClick(location.marker)
            }
          })
        })
      })
    }
  }

  function toggleMarkerAnimation(marker, time) {
    if (marker.getAnimation() !== null) {
      marker.setAnimation(null)
    } else {
      marker.setAnimation(google.maps.Animation.BOUNCE)
      setTimeout(() => {
        marker.setAnimation(null)
      }, time)
    }
  }

  function removeMarkers() {
    myLocations.forEach(marker => {
      marker.marker.setMap(null)
      marker.circle.setMap(null)
    })
    myLocations = []
    markerPosition.setMap(null)
  }

  function createNearestList(items) {
    nearestLocationsList.innerHTML = null

    items.forEach(item => {
      const div = document.createElement('div')
      div.classList.add('booking-item', 'booking-item--not-bg')
      const content = `
        <div class="booking-item__main" data-index="${item.id}">
            <img src="/images/logo.svg" class="booking-item__logo">
            <div class="booking-item__content">
                <div class="booking-item__city">${item.info.company}</div>
                <div class="booking-item__address">${item.info.city}, ${item.info.street}</div>
            </div>
        </div>`
      div.innerHTML = content

      div.addEventListener('click', () => markerClick(item.marker))

      nearestLocationsList.append(div)
    })
  }

  function openInfoWindow(marker, infoWindow, content) {
    infoWindow.setContent(infoWindowContent(content.info))
    infoWindow.open({
      map,
      anchor: marker,
      shouldFocus: false,
    })
  }

  function infoWindowContent({city, company, street}) {
    return `
     <div class="booking-item booking-item--not-bg">
        <div class="booking-item__marker-info">
            <img src="/images/logo.svg" class="booking-item__logo">
            <div class="booking-item__content">
                <div class="booking-item__city">${company}</div>
                <div class="booking-item__address">${city}, ${street}</div>
            </div>
        </div>
     </div>
    `
  }

  window.addEventListener('load', () => {
    if (!markers.length) {
      geocoder.geocode({'address': country}, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location)
          map.setZoom(6.5)
        }
      })
    }
  })

  document.head.appendChild(script)
}

export async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  })
  return await response.json()
}
