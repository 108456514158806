const openModals = document.querySelectorAll('[data-modal]')
const modals = document.querySelectorAll('.modal')
const html = document.querySelector('html')

if (openModals && modals) {
  modals.forEach( modal => {
    const closeModal = modal.querySelector('.modal__close')
    const closeOverlay = modal.querySelector('.modal__overlay')
    const formWrapper = modal.querySelector('form')

    openModals.forEach( item => {
      item.addEventListener('click', (event) => {
        event.preventDefault()

        let openModalNameBtn = event.target.dataset.modal
        let openModalName = modal.getAttribute('id')

        if  (openModalNameBtn === openModalName) {
          modal.classList.add('opened')
          html.classList.add('lock-scroll')
        }
      });
    });

    [closeModal, closeOverlay].map(item =>
      item.addEventListener('click', (event) => {
        event.preventDefault()
        modal.classList.remove('opened')
        html.classList.remove('lock-scroll')

        if (formWrapper) {
          formWrapper.reset()
        }
      })
    )
  })
}
