import IMask from 'imask';

const telInputs = document.querySelectorAll('[type*="phone"]')

if  (telInputs) {
  telInputs.forEach( tel => {
    IMask(
      tel, {
        mask: '+{38} (000) 000 00 00'
      });
  })
}
