const langTabForm = document.querySelector('.form-tab-lang')
const langTabContent = document.querySelectorAll('[data-lang-item]')

if (langTabForm) {
  const tabs = langTabForm.querySelectorAll('.form-tab-lang__item')

  tabs.forEach(tab => {
    tab.addEventListener('click', ({target}) => {
      const lang = target.dataset.lang

      tabs.forEach(tab => tab.classList.remove('active'))
      target.classList.add('active')
      showContent(lang)
    })
  })

  function showContent(nameLang) {
    langTabContent.forEach( content => {
      if  (nameLang === content.dataset.langItem) {
        content.classList.add('show')
      } else {
        content.classList.remove('show')
      }
    })
  }
}
