import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { postData } from './profileMap'

const ourStoresPage = document.querySelector('.our-stores')
const ourStoresMapWrapper = document.querySelector('.our-stores-map')
const btnCurrentPos = document.querySelector('.current-location')
const pointImg = '/images/maps/cart-point.svg'
const deliveryImg = '/images/maps/delivery-point.svg'
const minZoomLevel = 14
const country = 'Ukraine'
let bounds, geocoder, map, infoWindow, markers = [], markerClusterer = null

if (ourStoresMapWrapper) {
  const mapKey = ourStoresMapWrapper.dataset.key
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&region=ua&language=uk&callback=initMap`
  script.async = true

  const searchShop = document.querySelector('.search-shop-block')
  const searchShopBtn = searchShop.querySelector('.custom-select')
  const shopBtns = searchShop.querySelectorAll('.search-shop-block__actions .btn')
  const getLocations = ourStoresMapWrapper.dataset.locations

  window.initMap = () => {
    geocoder = new google.maps.Geocoder()
    infoWindow = new google.maps.InfoWindow({content: ''})
    bounds = new google.maps.LatLngBounds()

    btnCurrentPos ? btnCurrentPos.onclick = (event) => {
      event.preventDefault()

      const url = event.target.dataset.route

      btnCurrentPos.classList.add('spinner')
      btnCurrentPos.disabled = true

      getCurrentLocation(url)
    } : false

    map = new google.maps.Map(ourStoresMapWrapper, {
      zoom: 14,
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
    })

    getLocationsRequest(getLocations)

    shopBtns.forEach(shopBtn => {
      shopBtn.addEventListener('click', (event) => {
        event.preventDefault()
        let target = event.target

        shopBtns.forEach(btn => btn.classList.remove('active'))
        target.classList.add('active')

        if (target.classList.contains('delivery-shop')) {
          getLocationsRequest(`${getLocations}?delivery=1`)
        }
        if (target.classList.contains('all-shop')) {
          getLocationsRequest(getLocations)
        }
      })
    })

    window.addEventListener('shop-address-selected', event => {
      markers.map(item => {
        if (item.id === parseInt(event.detail.id, 10)) {
          markerClick(item.marker, item.content, item.infoWindow)
        }
      })
    })

    if (searchShopBtn) {
      searchShopBtn.addEventListener('click', (event) => {
        event.preventDefault()
        let target = event.target

        if (target.classList.contains('btn-search')) {
          let inputValue = searchShop.querySelector('select').value

          markers.map(item => {
            if (item.id === parseInt(inputValue, 10)) {
              markerClick(item.marker, item.content, item.infoWindow)
            }
          })
        }
      })
    }

    // const listener = google.maps.event.addListener(map, 'idle', () => {
    //   if (map.getZoom() < 11) {
    //     map.setZoom(14)
    //   }
    //   google.maps.event.removeListener(listener)
    // })
  }

  function getLocationsRequest(url) {
    fetch(url)
      .then(res => res.json())
      .then(data => addMarker(data, true))
      .then(() => {
        if (!markers.length) {
          geocoder.geocode({'address': country}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              map.setCenter(results[0].geometry.location)
              map.setZoom(6.5)
            }
          })
        }
      })
  }

  function addMarker(markerList, isCluster) {
    const markerItems = markerList.map(({position, content, isDelivery}) => {
      const marker = new google.maps.Marker({
        position: position,
        map,
        icon: isDelivery ? pointImg : deliveryImg,
      })

      marker.addListener('click', () => markerClick(marker, content, infoWindow))
      markers.push({id: position.id, marker, content, infoWindow});

      if (isCluster) {
        bounds.extend(position)
        map.fitBounds(bounds)
      } else {
        setBounce(marker);
      }

      return marker
    });

    if  (isCluster) {
      markerClusterer = new MarkerClusterer({map, markers: markerItems, renderer});
    }
  }

  const renderer = {
    render({count, position}) {
      return new google.maps.Marker({
        label: {
          text: String(count),
          color: '#4F2B04',
          fontSize: count > 99 ? '10px' : '12px',
          fontWeight: '700',
          className: 'position-count-label'
        },
        position,
        icon: '/images/maps/marker-conter.svg',
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      })
    }
  }

  function markerClick(marker, content, infoWindow) {
    map.setCenter(marker.position)
    map.setZoom(minZoomLevel)
    limitZoom(map)
    openInfoWindow(marker, infoWindow, content)

    if (marker.getAnimation() !== null) {
      marker.setAnimation(null)
    }
  }

  function openInfoWindow(marker, infoWindow, content) {
    infoWindow.setContent(infoWindowContent(content))
    infoWindow.open({
      map,
      anchor: marker,
      shouldFocus: false,
    })
  }

  function limitZoom(map) {
    const zoom = map.getZoom()

    map.setZoom(zoom > minZoomLevel ? minZoomLevel : zoom)
  }

  function infoWindowContent({phones, info, workSchedule, link}) {
    const phoneArr = []
    const scheduleArr = []

    phones.forEach(phone => {
      if (phone) {
        const divPhone = `<div class="item">${phone}</div>`
        phoneArr.push(divPhone)
      }
    })

    for (const [key, value] of Object.entries(workSchedule)) {
      const divWork = `<div class="item"><div class="day">${key}</div><div class="time">${value}</div></div>`
      scheduleArr.push(divWork)
    }

    return `
      <div class="info-place">
        <div class="info-place__left">
          <div class="info-place__logo not-hover">
            <img src="/images/logo.svg" alt="Logo">
          </div>
        </div>
        <div class="info-place__right">
          <div class="info-place__city">${info['city']}</div>
          <div class="info-place__street">${info['street']}</div>
          <div class="info-place__phones">
            ${phoneArr.join('')}
          </div>
          <div class="info-place__schedule-name">Графік роботи</div>
          <div class="info-place__schedule-items">
            ${scheduleArr.join('')}
          </div>
        </div>
      </div>
    `
  }

  window.addEventListener('load', () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    let value = parseInt(params.location, 10)

    if (value) {
      markers.forEach(item => {
        if (item.id === value) {
          markerClick(item.marker, item.content, item.infoWindow)
        }
      })
    }
  })

  clickItemShopSection()

  function clickItemShopSection() {
    const shopSection = document.querySelector('.shop-section')

    if (shopSection) {
      shopSection.addEventListener('click', ({target}) => {
        const addressItem = target.closest('.address-item')
        const addressItemIdx = addressItem.dataset.markerId

        markers.map(item => {
          if (item.id === parseInt(addressItemIdx, 10)) {
            markerClick(item.marker, item.content, item.infoWindow)
          }
        })

        ourStoresPage.scrollIntoView({block: 'center', behavior: 'smooth'})
      })
    }
  }

  function getCurrentLocation(url) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        postData(url, pos)
          .then((data) => {
            const {msg, html, markers: currentMarkers} = data

            if (html && html.length > 0) {
              const wrapper = document.createElement('div')
              wrapper.classList.add('shop-section')
              wrapper.innerHTML = html

              ourStoresPage.after(wrapper)
              clickItemShopSection()
            }

            if (currentMarkers && currentMarkers.length > 0) {
              removeMarkers().then(() => addMarker(currentMarkers, false))
            }
            if (msg) {
              btnCurrentPos.classList.add('empty')
              btnCurrentPos.innerText = msg
            }
          })

        btnCurrentPos.classList.remove('spinner')
        addMarkerCurrentLocation(pos)
      }, handleLocationError)
    } else {
      btnCurrentPos.classList.remove('spinner')
      alert('Браузер не підтримує геолокацію')
    }
  }

  function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    browserHasGeolocation ? alert('Помилка: Помилка служби геолокації.') : ('Помилка: Ваш браузер не підтримує геолокацію.')
  }

  function addMarkerCurrentLocation(position) {
    new google.maps.Marker({
      position: position,
      map,
    })

    map.setZoom(minZoomLevel)
    map.setCenter(position)
  }

  function setBounce(marker) {
    marker.setAnimation(google.maps.Animation.BOUNCE)
  }

  async function removeMarkers() {
    markers.forEach(marker => {
      marker.marker.setMap(null);
      markerClusterer.removeMarker(marker.marker);
    })
  }

  document.head.appendChild(script)
}
