const btnMenu = document.querySelector('.burger')
const html = document.querySelector('html')
const mobileMenu = document.querySelector('.mobile-menu')

if  (btnMenu) {
  btnMenu.addEventListener('click', () => {
    btnMenu.classList.toggle('active')
    html.classList.toggle('lock-scroll')
    mobileMenu.classList.toggle('show-menu')
  })
}
