const imagesWrapper = document.querySelector('.uploaded-files-container')

if (imagesWrapper) {
  const images = imagesWrapper.querySelectorAll('.uploaded-files-item')

  images.forEach(image => {
    image.addEventListener('click', ({target}) => {
      if (target.classList.contains('delete')) {
        image.remove()
      }
    })
  })
}

const hasProduction = document.querySelector('.has-production')
const hasArea = document.querySelector('.area')

if (hasProduction && hasArea) {
  const productionCheckbox = hasProduction.querySelector('[name="has_production"]')

  if (productionCheckbox.checked) {
    hasArea.classList.add('hidden')
  }

  productionCheckbox.addEventListener('change', () => {
    if (productionCheckbox.checked) {
      hasArea.classList.add('hidden')
    } else {
      hasArea.classList.remove('hidden')
    }
  })
}
