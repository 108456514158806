const counters = document.querySelectorAll('.counter')

if (counters) {
  counters.forEach(counter => {
    let plus = counter.querySelector('.plus')
    let minus =  counter.querySelector('.minus')
    let input =  counter.querySelector('input')

    plus.addEventListener('click', () => plusCount(input))
    minus.addEventListener('click', () => minusCount(input))
  })
}

function plusCount(input) {
  let value = parseInt(input.value, 10)
  value = isNaN(value) ? 0 : value
  value++
  input.value = value
}

function minusCount(input) {
  let value = parseInt(input.value, 10)

  if (value > 1) {
    value = isNaN(value) ? 0 : value
    value--
    input.value = value
  }
}
