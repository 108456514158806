const checkTelegram = document.querySelector('.check-telegram')

if (checkTelegram) {
  const input = checkTelegram.querySelector('input')
  const linkTelegram = checkTelegram.querySelector('.check-telegram__btn')

  input.addEventListener('click', ({target}) => {
    target.checked ? linkTelegram.classList.add('show') : linkTelegram.classList.remove('show')
  })
}
