const fileInputs = document.querySelectorAll('.c-file-input')

if (fileInputs) {
  fileInputs.forEach( input => {
    const inputFile = input.querySelector('.c-file-input__input')
    const inputLabel = input.querySelector('.c-file-input__label')

    inputFile.addEventListener('input', () => {
      inputLabel.innerText = inputFile.files[0].name
    })
  })
}
