const inputs = document.querySelectorAll('.form-group input')
const textarea = document.querySelectorAll('.form-group textarea')

const formElements = [...inputs, ...textarea]
const events = ['input', 'focusout']

if (formElements.length) {
  formElements.forEach(input => {
    events.forEach( event => {
      input.addEventListener(event, () => {
        if (input.value !== '') {
          input.classList.add('has-value')
        } else {
          input.classList.remove('has-value')
        }
      })
    })
  })
}
