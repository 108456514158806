const links = document.querySelectorAll('[data-href]');

if  (links.length) {
  links.forEach( link => {
    link.onclick = ({target}) => {
      if  (target.nodeName !== 'A') {
        const curLink = target.closest('.address-item');

        if  (curLink) {
          window.location = curLink.dataset.href;
        }
      }
    }
  })
}
