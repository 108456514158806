const cartWrapper = document.querySelector('.side-cart');
const cartBtn = document.querySelector('.cart');
const html = document.querySelector('html');

if (cartWrapper && cartBtn) {
  const close = cartWrapper.querySelector('.side-cart__close');
  const overlay = cartWrapper.querySelector('.side-cart__overlay');

  [close, overlay].forEach(item => {
    item.addEventListener('click', toggleCart);
  })

  cartBtn.addEventListener('click', () => toggleCart())

  function toggleCart() {
    cartWrapper.classList.toggle('open-cart');
    html.classList.toggle('lock-scroll');
  }
}
