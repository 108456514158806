const selectCompanies = document.querySelector('.profile-companies')

if (selectCompanies) {
  const btn = selectCompanies.querySelector('.profile-companies__active.multiple')
  const wrapper = selectCompanies.querySelector('.profile-companies__items')

  if (btn) {
    btn.addEventListener('click', () => {
      btn.classList.toggle('active')
      wrapper.classList.toggle('show')
    })

    document.body.addEventListener('click',  (event) => {
      if (!selectCompanies.contains(event.target)) {
        btn.classList.remove('active')
        wrapper.classList.remove('show')
      }
    })
  }
}
