let x, i, j, l, ll, selElem, a, b, c
x = document.getElementsByClassName('custom-select')
l = x.length
const minItemsForSearch = 1

window.addEventListener('load', () => {
  for (i = 0; i < l; i++) {
    let listItem, inputSearch, selectSearch
    selElem = x[i].getElementsByTagName('select')[0]
    ll = selElem.length
    a = document.createElement('DIV')
    a.setAttribute('class', 'select-selected')
    a.innerHTML = selElem.options[selElem.selectedIndex].innerHTML
    x[i].appendChild(a)
    b = document.createElement('DIV')
    b.setAttribute('class', 'select-items select-hide')

    listItem = document.createElement('DIV')
    listItem.setAttribute('class', 'select-list')

    if (x[i].classList.contains('search-element') && selElem.length > minItemsForSearch) {
      const btnSearch = document.createElement('DIV')
      btnSearch.setAttribute('class', 'btn-search')
      x[i].prepend(btnSearch)

      inputSearch = document.createElement('INPUT')
      inputSearch.setAttribute('type', 'text')

      selectSearch = document.createElement('DIV')
      selectSearch.setAttribute('class', 'select-search')
      selectSearch.appendChild(inputSearch)

      b.appendChild(selectSearch)

      selectSearch.addEventListener('click', (event) => {
        event.stopPropagation()
      })

      inputSearch.addEventListener('input', ({target}) => {
        let searchValue = target.value.toLowerCase()

        showValueSearch(listItem, searchValue)
      })
    }

    b.appendChild(listItem)

    for (j = 0; j < ll; j++) {
      c = document.createElement('DIV')
      c.innerHTML = selElem.options[j].innerHTML
      c.setAttribute('data-value', selElem.options[j].value)

      if (j === selElem.selectedIndex) {
        c.setAttribute('class', 'same-as-selected')
      }

      c.addEventListener('click', (e) => addListenerItem(e))

      listItem.appendChild(c)
    }

    x[i].appendChild(b)
    a.addEventListener('click', function (e) {
      e.stopPropagation()
      closeAllSelect(this)

      this.nextSibling.classList.toggle('select-hide')
      this.classList.toggle('select-arrow-active')

      if  (inputSearch) {
        setTimeout( () => inputSearch.focus(), 500)
      }
    })

    window.addEventListener('shop-delivery-updated', event => {
      let items = event.detail.shops

      listItem.querySelectorAll('[data-value]').forEach((item, idx) => {
        if (idx > 0) {
          item.remove()
        }
      })

      items.forEach(item => {
        let option = document.createElement('div')
        option.setAttribute('data-value', item.id)
        option.innerText = item.address
        listItem.append(option)

        option.addEventListener('click', (e) => addListenerItem(e))
      })
    })

    function addListenerItem(e) {
      let value = e.target.dataset.value
      let y, i, k, s, h, sl, yl, eventName
      s = e.target.parentNode.parentNode.parentNode.getElementsByTagName('select')[0]
      eventName = s.dataset.event
      sl = s.length
      h = e.target.parentNode.parentNode.previousSibling
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML === e.target.innerHTML) {
          s.selectedIndex = i
          Livewire.emit(eventName, value)
          h.innerHTML = e.target.innerHTML
          y = e.target.parentNode.parentNode.getElementsByClassName('same-as-selected')
          yl = y.length
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute('class')
          }
          e.target.setAttribute('class', 'same-as-selected')
          break
        }
      }
      h.click()
    }
  }

  document.addEventListener('click', closeAllSelect)
})

function closeAllSelect(element) {
  let x, y, i, xl, yl, arrNo = []
  x = document.getElementsByClassName('select-items')
  y = document.getElementsByClassName('select-selected')
  xl = x.length
  yl = y.length
  for (i = 0; i < yl; i++) {
    if (element === y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove('select-arrow-active')

      let isValueSearch = y[i].nextSibling.querySelector('.select-search input')
      let valueSearchItems = y[i].nextSibling.querySelector('.select-list')

      if (isValueSearch) {
        isValueSearch.value = null
        showValueSearch(valueSearchItems, isValueSearch.value)
      }
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide')
    }
  }
}

function showValueSearch(listItem, searchValue) {
  let countItems = listItem.querySelectorAll('div')

  countItems.forEach(item => {
    item.style.display = 'none'
    item.classList.add('hidden')

    if (item.innerText.toLowerCase().includes(searchValue)) {
      item.style.display = 'flex'
      item.classList.remove('hidden')
    }
  })
}
