let button = document.querySelector('.btn__click')

if (button) {
  let item = document.querySelector('.form__accordion')

  button.addEventListener('click', function () {

    let accordionContent = item.querySelector('.form__accordion__content')
    if (accordionContent.hasAttribute('style')) {
      accordionContent.removeAttribute('style')
    } else {
      accordionContent.style.height = accordionContent.scrollHeight + 'px'
    }
  })
}
