const statistics = document.querySelector('.statistics')

if (statistics) {
  const statisticItems = statistics.querySelectorAll('.statistics__item .number')

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target

        if (!target.classList.contains('init')) {
          target.classList.add('init')

          statisticItems.forEach(target => {
            let number = parseInt(target.innerHTML, 10)
            animateValue(target, 0, number, 2000)
          })
        }
      }
    })
  }, {
    root: null,
    rootMargin: '0px',
    threshold: [0, .5]
  })

  observer.observe(statistics)
}

function animateValue(target, start, end, duration) {
  let startTimestamp

  const step = (timestamp) => {
    if (!startTimestamp) {
      startTimestamp = timestamp
    }
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    const value = progress * (end - start) + start

    target.innerHTML = Math.floor(value)

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}
