const mapLocation = document.querySelector('.block-map')

if (mapLocation) {
  const mapKey = mapLocation.dataset.key
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&region=ua&language=uk&callback=initMap`
  script.async = true
  const pointImg = '/images/maps/cart-point.svg'
  const deliveryImg = '/images/maps/delivery-point.svg'
  const maxZoomLevel = 14
  const country = "Ukraine";
  let map, geocoder, markers = []

  window.initMap = () => {
    const bounds = new google.maps.LatLngBounds()
    geocoder = new google.maps.Geocoder()

    fetch(mapLocation.dataset.locations)
      .then(res => res.json())
      .then(data =>
        data.forEach(marker => addMarker(marker))
      )
      .then(() => {
        checkMarkers();
      })

    map = new google.maps.Map(mapLocation, {
      zoom: maxZoomLevel,
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
    })

    function addMarker(markerItem) {
      const {position, isDelivery} = markerItem

      const marker = new google.maps.Marker({
        position: position,
        map: map,
        icon: isDelivery ? pointImg : deliveryImg,
      })

      marker.addListener('click', () => {
        map.setZoom(maxZoomLevel)
        map.setCenter(marker.position)
      })

      markers.push(marker)

      bounds.extend(position)
      map.fitBounds(bounds)

      const listener = google.maps.event.addListener(map, 'idle', function () {
        if (map.getZoom() > 16) map.setZoom(maxZoomLevel)
        google.maps.event.removeListener(listener)
      })
    }
  }

  function checkMarkers() {
    if  (!markers.length) {
      geocoder.geocode( {'address' : country}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
          map.setZoom(5)
        }
      });
    }
  }

  document.head.appendChild(script)
}
