window.addEventListener('feedback-sent', event => {
  const modalSend = document.getElementById('feedback-sent');
  const target = event.target;
  const form = target.querySelector('form');
  const preview = target.querySelector('.preview');

  form.reset();

  if (preview) {
    preview.innerHTML = null
  }

  modalSend.classList.add('opened')

  setTimeout(() => {
    modalSend.classList.remove('opened')
  }, 5000)
})
