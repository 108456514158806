window.onload = () => {
  const navbar = document.querySelector('header')
  const body = document.querySelector('body')
  let c, currentScrollTop = 0

  window.onresize = () => {
    headerTop()
  }

  if  (navbar && body) {
    window.addEventListener('scroll', (e) => {
      let a = window.pageYOffset
      let b = navbar.clientHeight

      if (!navbar.classList.contains('open-menu')) {
        currentScrollTop = a

        if (c < currentScrollTop && a > b + b) {
          navbar.classList.add('scroll-up')
          body.classList.add('hidden-menu')
        }
        else if (a < 10) {
          navbar.classList.remove('scroll-up')
          body.classList.remove('hidden-menu')
        }
        else if (c > currentScrollTop && !(a <= b)) {
          navbar.classList.remove('scroll-up')
          body.classList.remove('hidden-menu')
        }
        c = currentScrollTop
      }
    })

    headerTop()
  }

  function headerTop() {
    body.style.paddingTop = navbar.clientHeight + 'px'
  }
}


